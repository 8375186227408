import React from 'react'
import './helpcenter.css'
import logoicon from "../../assets/images/helpcenter-Icon 2.svg"
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'

export default function HelpCenter() {
    return (
        <div className='helpcenter'>
            <Navbar>

            </Navbar>
            <div>
                <section id="main-content" class="section hero hero_show ">
                    <div class="hero-inner ">
                        <h2 class="visibility-hidden">Search</h2>
                        <h1 class="welcome_text">Welcome to our Support</h1>

                        <form role="search" class="search search-full" data-search="" data-instant="true" autocomplete="off" action="/hc/en-us/search" accept-charset="UTF-8" method="get">
                            <input name="utf8" type="hidden" value="✓" autocomplete="off" />
                            <input type="search" name="query" id="query" placeholder="Type your question here..." autocomplete="off" aria-label="Type your question here..." />
                            <input type="submit" name="commit" value="Search" /></form>
                    </div>
                </section>
            </div>

            {/* <div class="container ">
                <section class="section knowledge-base">
                    <h2 class="visibility-hidden">Categories</h2>
                    <section class="categories blocks">


                        <ul class="blocks-list">


                            <li class="blocks-item" id="16138129743257">
                                <a href="/hc/en-us/categories/16138129743257-Kasookoo-Connect" class="blocks-item-link">
                                    <span class="category-icon"><img src={logoicon} style={{height:"40px"}}/><i class="fa-solid fa-book-open-reader"></i></span>
                                    <span class="blocks-item-title">Kasookoo Connect</span>
                                    <span class="blocks-item-description"></span>
                                </a>
                            </li>



                            <li class="blocks-item" id="16431331745177">
                                <a href="/hc/en-us/categories/16431331745177-Kasookoo-Voice-Numbering" class="blocks-item-link">
                                    <span class="category-icon"><img src={logoicon} style={{height:"40px"}}/><i class="fa-solid fa-book-open-reader"></i></span>

                                    <span class="blocks-item-title">Kasookoo Voice Numbering</span>
                                    <span class="blocks-item-description"></span>
                                </a>
                            </li>



                            <li class="blocks-item" id="16388109763097">
                                <a href="/hc/en-us/categories/16388109763097-Kasookoo-CCaaS" class="blocks-item-link">
                                    <span class="category-icon"><img src={logoicon} style={{height:"40px"}}/><i class="fa-solid fa-book-open-reader"></i></span>

                                    <span class="blocks-item-title">Kasookoo CCaaS</span>
                                    <span class="blocks-item-description"></span>
                                </a>
                            </li>



                            <li class="blocks-item" id="16429426770969">
                                <a href="/hc/en-us/categories/16429426770969-Kasookoo-Customer-Portal" class="blocks-item-link">
                                    <span class="category-icon"><img src={logoicon} style={{height:"40px"}}/><i class="fa-solid fa-book-open-reader"></i></span>

                                    <span class="blocks-item-title">Kasookoo Customer Portal</span>
                                    <span class="blocks-item-description"></span>
                                </a>
                            </li>



                            <li class="blocks-item" id="16406155594649">
                                <a href="/hc/en-us/categories/16406155594649-Kasookoo-Campaigns" class="blocks-item-link">
                                    <span class="category-icon"><img src={logoicon} style={{height:"40px"}}/><i class="fa-solid fa-book-open-reader"></i></span>

                                    <span class="blocks-item-title">Kasookoo Campaigns</span>
                                    <span class="blocks-item-description"></span>
                                </a>
                            </li>



                            <li class="blocks-item" id="16525959726873">
                                <a href="/hc/en-us/categories/16525959726873-Kasookoo-KYC-Dashboard" class="blocks-item-link">
                                    <span class="category-icon"><img src={logoicon} style={{height:"40px"}}/><i class="fa-solid fa-book-open-reader"></i></span>

                                    <span class="blocks-item-title">Kasookoo KYC Dashboard</span>
                                    <span class="blocks-item-description"></span>
                                </a>
                            </li>



                            <li class="blocks-item" id="16409859081625">
                                <a href="/hc/en-us/categories/16409859081625-Internal" class="blocks-item-link">
                                    <span class="category-icon"><img src={logoicon} style={{height:"40px"}}/><i class="fa-solid fa-book-open-reader"></i></span>

                                    <span class="blocks-item-title">Internal</span>
                                    <span class="blocks-item-description"></span>
                                </a>
                            </li>



                            <li class="blocks-item" id="16409416711321">
                                <a href="/hc/en-us/categories/16409416711321-General" class="blocks-item-link">
                                    <span class="category-icon"><img src={logoicon} style={{height:"40px"}}/><i class="fa-solid fa-book-open-reader"></i></span>

                                    <span class="blocks-item-title">General</span>
                                    <span class="blocks-item-description"></span>
                                </a>
                            </li>


                        </ul>

                    </section>

                    <section class="quick-articles ">


                    </section>
                </section>
            </div>
            <div class="container ">
                <section class="section home-section activity pt-80 mb-30 pt-0">
                    <div data-app="recent-activity" data-url="/hc/api/internal/recent_activities?locale=en-us"><div class="recent-activity"><h2 class="recent-activity-header">Recent activity</h2><div role="status" class="recent-activity-list" ><section class="recent-activity-item" data-recent-activity-action="article_created">
                        <h3><a class="recent-activity-item-parent" href="/hc/en-us/sections/16409417480985-Release-Notes">Release Notes</a></h3>
                        <a class="recent-activity-item-link" href="/hc/en-us/articles/18890972049049-Release-Notes-29-05-2023">Release Notes-29/05/2023</a>
                        <div class="recent-activity-item-meta">
                            <div class="recent-activity-item-time">Article created 1 day ago</div>

                            <div class="recent-activity-item-comment">
                                <span class="recent-activity-comment-icon" aria-hidden="true" data-comment-count="0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" focusable="false" viewBox="0 0 12 12">
                                        <path fill="none" stroke="currentColor" d="M1 .5h10c.3 0 .5.2.5.5v7c0 .3-.2.5-.5.5H6l-2.6 2.6c-.3.3-.9.1-.9-.4V8.5H1C.7 8.5.5 8.3.5 8V1C.5.7.7.5 1 .5z"></path>
                                    </svg>
                                </span>
                                <span class="recent-activity-accessibility-label">
                                    Number of comments: 0
                                </span>
                            </div>

                        </div>
                    </section><section class="recent-activity-item" data-recent-activity-action="article_created">
                            <h3><a class="recent-activity-item-parent" href="/hc/en-us/sections/16409417480985-Release-Notes">Release Notes</a></h3>
                            <a class="recent-activity-item-link" href="/hc/en-us/articles/18406359594009-Release-Notes-15-05-2023">Release Notes-15/05/2023</a>
                            <div class="recent-activity-item-meta">
                                <div class="recent-activity-item-time">Article created 15 days ago</div>

                                <div class="recent-activity-item-comment">
                                    <span class="recent-activity-comment-icon" aria-hidden="true" data-comment-count="0">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" focusable="false" viewBox="0 0 12 12">
                                            <path fill="none" stroke="currentColor" d="M1 .5h10c.3 0 .5.2.5.5v7c0 .3-.2.5-.5.5H6l-2.6 2.6c-.3.3-.9.1-.9-.4V8.5H1C.7 8.5.5 8.3.5 8V1C.5.7.7.5 1 .5z"></path>
                                        </svg>
                                    </span>
                                    <span class="recent-activity-accessibility-label">
                                        Number of comments: 0
                                    </span>
                                </div>

                            </div>
                        </section><section class="recent-activity-item" data-recent-activity-action="article_created">
                            <h3><a class="recent-activity-item-parent" href="/hc/en-us/sections/16406677507737-Configuration">Configuration</a></h3>
                            <a class="recent-activity-item-link" href="/hc/en-us/articles/16407100501017-Voicemail">Voicemail</a>
                            <div class="recent-activity-item-meta">
                                <div class="recent-activity-item-time">Article created 21 days ago</div>

                                <div class="recent-activity-item-comment">
                                    <span class="recent-activity-comment-icon" aria-hidden="true" data-comment-count="0">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" focusable="false" viewBox="0 0 12 12">
                                            <path fill="none" stroke="currentColor" d="M1 .5h10c.3 0 .5.2.5.5v7c0 .3-.2.5-.5.5H6l-2.6 2.6c-.3.3-.9.1-.9-.4V8.5H1C.7 8.5.5 8.3.5 8V1C.5.7.7.5 1 .5z"></path>
                                        </svg>
                                    </span>
                                    <span class="recent-activity-accessibility-label">
                                        Number of comments: 0
                                    </span>
                                </div>

                            </div>
                        </section><section class="recent-activity-item" data-recent-activity-action="article_created">
                            <h3><a class="recent-activity-item-parent" href="/hc/en-us/sections/16406677507737-Configuration">Configuration</a></h3>
                            <a class="recent-activity-item-link" href="/hc/en-us/articles/16407105561881-Ring-Group">Ring Group</a>
                            <div class="recent-activity-item-meta">
                                <div class="recent-activity-item-time">Article created 21 days ago</div>

                                <div class="recent-activity-item-comment">
                                    <span class="recent-activity-comment-icon" aria-hidden="true" data-comment-count="0">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" focusable="false" viewBox="0 0 12 12">
                                            <path fill="none" stroke="currentColor" d="M1 .5h10c.3 0 .5.2.5.5v7c0 .3-.2.5-.5.5H6l-2.6 2.6c-.3.3-.9.1-.9-.4V8.5H1C.7 8.5.5 8.3.5 8V1C.5.7.7.5 1 .5z"></path>
                                        </svg>
                                    </span>
                                    <span class="recent-activity-accessibility-label">
                                        Number of comments: 0
                                    </span>
                                </div>

                            </div>
                        </section><section class="recent-activity-item" data-recent-activity-action="article_created">
                            <h3><a class="recent-activity-item-parent" href="/hc/en-us/sections/16406677507737-Configuration">Configuration</a></h3>
                            <a class="recent-activity-item-link" href="/hc/en-us/articles/16806236814489-Extensions">Extensions</a>
                            <div class="recent-activity-item-meta">
                                <div class="recent-activity-item-time">Article created 21 days ago</div>

                                <div class="recent-activity-item-comment">
                                    <span class="recent-activity-comment-icon" aria-hidden="true" data-comment-count="0">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" focusable="false" viewBox="0 0 12 12">
                                            <path fill="none" stroke="currentColor" d="M1 .5h10c.3 0 .5.2.5.5v7c0 .3-.2.5-.5.5H6l-2.6 2.6c-.3.3-.9.1-.9-.4V8.5H1C.7 8.5.5 8.3.5 8V1C.5.7.7.5 1 .5z"></path>
                                        </svg>
                                    </span>
                                    <span class="recent-activity-accessibility-label">
                                        Number of comments: 0
                                    </span>
                                </div>

                            </div>
                        </section >
                        </div>
                        <div class="recent-activity-no-activities" style={{display: "none"}}> </div>
                        <div class="recent-activity-loader" style={{display: "none"}}>...</div>
                        <div class="recent-activity-controls"   >
                            <a href="#" data-recent-activity-seemore="" style={{backgroundColor:"#27A7CC"}} >
                                See more
                                <span style={{ clip: "rect(0 0 0 0)", clipPath: "inset(50%)",height: "1px", overflow: "hidden",    position: "absolute",    whiteSpace: "nowrap",  width:" 1px"}}>
                                    items from recent activity
                                </span>

                            </a>
                        </div></div></div>
                </section>
            </div> */}
            <Footer></Footer>
        </div>
    )
}
