import "./aboutus.css";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import comapaign_img from "../../assets/images/consulting-img.png";
import aboutjoin from "../../assets/images/about-join.png";
import nav_img from "../../assets/images/main_right.png";
import vision from "../../assets/images/vision.png";
import target from "../../assets/images/target.png";
import deter from "../../assets/images/determination.png";
import teamw from "../../assets/images/teamwork.png";
import teamw1 from "../../assets/images/teamwork-laptop-planning-with-face-business-people-office-strategy-project-management-idea-diversity-support-community-with-group-employee-startup-lea.png";
import cust from "../../assets/images/customer.png";
import owner from "../../assets/images/ownership.png";
import l1 from "../../assets/images/l1.png";
import l2 from "../../assets/images/l2.png";
import l3 from "../../assets/images/l3.png";
import l4 from "../../assets/images/l4.png";
import l5 from "../../assets/images/l5.png";
import about_team_1 from "../../assets/images/ABOUT TEAM.png";
import about_team_2 from "../../assets/images/about_team_2.png";
import Slider from "../../components/Slider/Slider";
import down_arrow from "../../assets/images/down-arrow.svg";

import SSEAN from "../../assets/images/ssean-1.v1.jpg"
import launchAfrica from "../../assets/images/Logo-Launch-Africa.png"
import tomDavis from "../../assets/images/TomiDavies.png"
import mattBrown from "../../assets/images/MATTBROWN .png"

import teamMember1Img from "../../assets/images/teamMember1Ahmer.png";
import teamMember2Img from "../../assets/images/TeamMember2Waqas.png";
import teamMember3Img from "../../assets/images/TeamMember3Akin.png";

export default function Aboutus(props) {
  const toggleContent = (event, id) => {
    const element = event.target;
    var content = document.querySelector(".desc-" + id);
    if (content.classList.contains("d-none")) {
      element.style.transform = "rotate(180deg)";
    } else {
      element.style.transform = "rotate(90deg)";
    }
    content.classList.toggle("d-none");
    // console.log(content.style.display)
    // if (content.style.display === 'none') {
    //   content.style.display = 'block';
    //   element.style.transform = 'rotate(180deg)';
    // } else {
    //   content.style.display = 'none';
    //   element.style.transform = 'rotate(90deg)';

    // }
  };
  return (
    <>
      <div className="about-us">
        <Navbar
          heading="Introducing Kasookoo, your trusted CX partner!"
          text="Our mission is to bring businesses closer to their customers through cutting-edge cloud communication solutions. Through our transformative solutions, we empower businesses in Africa and other regions to reimagine customer experiences, fostering remarkable connections, and unlocking unparallel operational efficiency for their teams."
          img={nav_img}
          first_section={true}
          setIsFormOpen={props.setIsFormOpen}
          isFormOpen={props.isFormOpen}
        ></Navbar>
        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="a-btn col-12 mx-auto col-md-8">
                <h2 class="style-h2">The Essence of Kasookoo</h2>
              </div>
            </div>
          </div>
        </div>

        <div
          id="num-section-2"
          class="w-100 position-relative block-centered_title "
        >
          <style></style>

          <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
            <div class="container">
              <div class="row ">
                <div class="col-12 mx-auto col-md-8">
                  <p class="p-small">
                    We ignite meaningful conversations that give rise to
                    innovative customer experiences, and new growth
                    opportunities that allow us to challenge conventional norms
                    in the customer experience domain.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="num-section-3"
          class="w-100 position-relative block-center_button pb-5"
        >
          <style></style>
          <div class="container section-center_button">
            <div class="row align-items-center text-left text-md-center">
              <div class="col-12 ">
                <a href="#corevalues" class="btn btn-arrow" target="">
                  Learn More About Our Company Values
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          id="num-section-4"
          class=" position-relative block-desktop_and_mobile_content container-100-override"
        >
          <div class="w-100 section-desktop_and_mobile_content  container-100-override">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12 col-md-auto d-none d-md-block ">
                  <p>
                    <img
                      decoding="async"
                      loading="lazy"
                      class="wp-image-2131 size-full aligncenter"
                      src={about_team_1}
                      alt=""
                      width="1943"
                      height="550"
                      sizes="(max-width: 1943px) 100vw, 1943px"
                    />
                  </p>
                </div>
                <div class="col-12 col-md-auto d-md-none ">
                  <p>
                    <img
                      decoding="async"
                      loading="lazy"
                      class="wp-image-2143 size-full aligncenter"
                      src={about_team_2}
                      alt=""
                      width="449"
                      height="488"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-md-none bg-archer bg-archer-up">
          <svg
            width="400"
            height="87"
            viewBox="0 0 400 87"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0C0 0 80.5 40.5 200 40.5C319.5 40.5 400 0 400 0V87H0V0Z"
              fill="#f1f5fe"
            ></path>
          </svg>
        </div>
        <div class="d-none d-md-block bg-archer bg-archer-up">
          <svg
            width="2500"
            height="178"
            viewBox="0 0 2500 178"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0C0 0 506.625 124 1253.5 124C2000.38 124 2500 0 2500 0V178H0L0 0Z"
              fill="#f1f5fe"
            ></path>
          </svg>
        </div>
        <div
          id="num-section-5"
          class="w-100 position-relative block-centered_title pt-3"
        >
          <style></style>

          <div class="w-100 section-centered_title pt-3 py-3 align-items-center text-left text-md-center">
            <div class="container">
              <div class="row ">
                <div class="col-12 mx-auto col-md-8">
                  <h2 class="style-h2">Bringing Ease to Customer Engagement</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="num-section-6"
          class="w-100 position-relative block-centered_title "
        >
          <style></style>

          <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
            <div class="container">
              <div class="row ">
                <div class="col-12 mx-auto col-md-8">
                  <p class="p-small">
                    We are rewriting the path of customer engagement in Africa
                    through new experiences, insightful perspectives and
                    unlimited growth opportunities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="num-section-7"
          class="w-100 position-relative block-image_on_the_left_and_list_items_on_the_right "
        >
          <style></style>
          <div class="w-100 section-image_on_the_left_and_list_items_on_the_right py-3">
            <div class="container">
              <div class="row">
                <div class="col-12 col-lg-6 mb-3 mb-md-0">
                  <img
                    src={teamw1}
                    alt="Group 1000004881"
                    class="w-100 h-auto mb-4"
                  />
                </div>
                <div class="col-12 col-lg-6">
                  <div class="w-100  d-md-flex box-item">
                    <div class="w-100 d-block mb-3 pe-3 w-sm-15">
                      <img src={vision} alt="Our Vision" title="Our Vision" />
                    </div>
                    <div class="w-100 d-block w-sm-66 clearfix">
                      <div class="w-100 d-block mb-2">
                        <h4 class="style-h4">Our Vision</h4>
                      </div>
                      <div class="w-100 d-block mb-3">
                        <div class="p-small pb-3">
                          To transform customer experiences through innovative
                          customer communication solutions.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-100 d-md-flex box-item">
                    <div class="w-100 d-block mb-3 pe-3 w-sm-15">
                      <img src={target} alt="Our Mission" title="Our Mission" />
                    </div>
                    <div class="w-100 d-block w-sm-66 clearfix">
                      <div class="w-100 d-block mb-2">
                        <h4 class="style-h4">Our Mission</h4>
                      </div>
                      <div class="w-100 d-block mb-3">
                        <div class="p-small pb-3">
                          We empower businesses to connect, engage and delight
                          their customers through reliable and impactful
                          communication technology solutions that drive customer
                          satisfaction and business growth, Africa first and
                          other regions next.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class=" d-sm-none bg-archer bg-archer-down">
          <svg
            width="400"
            height="87"
            viewBox="0 0 400 87"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 87C0 87 80.5 46.5 200 46.5C319.5 46.5 400 87 400 87V0H0V87Z"
              fill="#f1f5fe"
            ></path>
          </svg>
        </div>
        <div class="d-none d-sm-block bg-archer bg-archer-down">
          <svg
            width="2500"
            height="178"
            viewBox="0 0 2500 178"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 178C0 178 506.625 54 1253.5 54C2000.38 54 2500 178 2500 178V0H0L0 178Z"
              fill="#f1f5fe"
            ></path>
          </svg>
        </div>
        <div
          id="core-values"
          class="w-100 position-relative block-centered_title "
        >
          <style></style>

          <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
            <div class="container">
              <div class="row ">
                <div class="col-12 mx-auto col-md-8">
                  <h2 id="corevalues" class="style-h2">
                    Our Core Values
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="num-section-9"
          class="w-100 position-relative block-centered_title pb-4"
        >
          <style></style>

          <div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
            <div class="container">
              <div class="row ">
                <div class="col-12 mx-auto col-md-8">
                  <p class="p-small">
                    We prioritize the needs and satisfaction of our customers,
                    delivering exceptional experiences and building strong
                    relationships.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="num-section-10"
          class="w-100 position-relative block-icon_boxes pb-0"
        >
          <style></style>
          <style></style>
          <div class="w-100 section-icon_boxes">
            <div class="container">
              <div class="row">
                <div class="col-12 col-md-6 mb-5">
                  <div class="box d-block d-lg-flex">
                    <div
                      class="icon p-3 my-auto d-none d-lg-flex"
                      style={{ background: "#f9f6ff" }}
                    >
                      <img src={deter} class="mx-auto" alt="Frame" />
                    </div>
                    <div class="mobile-icon w-100 d-block d-lg-none text-end p-3">
                      <img src={deter} class="ms-auto me-0" alt="Frame" />
                    </div>
                    <div class="content p-3">
                      <h4 class="style-h4">Customer Focus</h4>
                      <span class="p-small">
                        We prioritize the needs and satisfaction of our
                        customers, delivering exceptional experiences and
                        building strong relationships.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 mb-5">
                  <div class="box d-block d-lg-flex">
                    <div
                      class="icon p-3 my-auto d-none d-lg-flex"
                      style={{ background: "#f9f6ff" }}
                    >
                      <img src={teamw} class="mx-auto" alt="hand" />
                    </div>
                    <div class="mobile-icon w-100 d-block d-lg-none text-end p-3">
                      <img src={teamw} class="ms-auto me-0" alt="hand" />
                    </div>
                    <div class="content p-3">
                      <h4 class="style-h4">Inovation</h4>
                      <span class="p-small">
                        We embrace continuous improvement and seek innovative
                        solutions to enhance customer engagement, leveraging
                        cutting-edge technology and industry trends.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 mb-5">
                  <div class="box d-block d-lg-flex">
                    <div
                      class="icon p-3 my-auto d-none d-lg-flex"
                      style={{ background: "#f9f6ff" }}
                    >
                      <img src={cust} class="mx-auto" alt="Group 1000004483" />
                    </div>
                    <div class="mobile-icon w-100 d-block d-lg-none text-end p-3">
                      <img
                        src={cust}
                        class="ms-auto me-0"
                        alt="Group 1000004483"
                      />
                    </div>
                    <div class="content p-3">
                      <h4 class="style-h4">Operational Excellence</h4>
                      <span class="p-small">
                        We strive for operational excellence, implementing
                        efficient processes and leveraging technology to
                        optimize performance, and increase productivity.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 mb-5">
                  <div class="box d-block d-lg-flex">
                    <div
                      class="icon p-3 my-auto d-none d-lg-flex"
                      style={{ background: "#f9f6ff" }}
                    >
                      <img src={owner} class="mx-auto" alt="Group 1000004484" />
                    </div>
                    <div class="mobile-icon w-100 d-block d-lg-none text-end p-3">
                      <img
                        src={owner}
                        class="ms-auto me-0"
                        alt="Group 1000004484"
                      />
                    </div>
                    <div class="content p-3">
                      <h4 class="style-h4">Collaboration</h4>
                      <span class="p-small">
                        We foster a culture of collaboration and partnerships,
                        encouraging teamwork and open communication to drive
                        innovation, problem-solving, and continuous improvement.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class=" d-md-none bg-archer bg-archer-up">
          <svg
            width="400"
            height="87"
            viewBox="0 0 400 87"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0C0 0 80.5 40.5 200 40.5C319.5 40.5 400 0 400 0V87H0V0Z"
              fill="#f1f5fe"
            ></path>
          </svg>
        </div>
        <div class=" d-none d-md-block bg-archer bg-archer-up">
          <svg
            width="2500"
            height="178"
            viewBox="0 0 2500 178"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0C0 0 506.625 124 1253.5 124C2000.38 124 2500 0 2500 0V178H0L0 0Z"
              fill="#f1f5fe"
            ></path>
          </svg>
        </div>
        <div
          id="num-section-15"
          class="w-100 position-relative block-centered_title pt-5"
        >
          <style></style>

          <div class="w-100 section-centered_title pt-5 py-3 align-items-center text-left text-md-center">
            <div class="container">
              <div class="row ">
                <div class="col-12 mx-auto col-md-8">
                  <h2 class="style-h2">Meet the Kasookoo Team</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="num-section-16"
          class="w-100 position-relative block-centered_title "
        >
          <style></style>

          <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
            <div class="container">
              <div class="row ">
                <div class="col-12 mx-auto col-md-8">
                  <p class="p-small">
                    Kasookoo is led by a group of committed experts in customer
                    experience who have pulled together a wealth of valuable
                    experience and deep-rooted industry knowledge to drive the
                    success of the firm.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="num-section-17"
          class="w-100 position-relative block-team_block "
        >
          <div class="w-100 section-team_block">
            <div class="container">
              <div class="row">
                <div class="col-12 col-md-6 col-lg-4 mb-5">
                  <div class="team-member">
                    <div class="tm-photo text-center mx-auto mb-3">
                      <img src={teamMember1Img} alt="Frame 46260" />
                    </div>
                    <div class="tm-content">
                      <div class="name p-small pb-3 mb-3">
                        Ahmer Javaid
                        <span class="float-end">
                          <img
                            class="view-caret"
                            src={down_arrow}
                            alt="caret"
                            onClick={(event) => {
                              toggleContent(event, 1);
                            }}
                            style={{ transform: "rotate(90deg)" }}
                          />
                        </span>
                      </div>
                      <div class="desc p-small">Co-founder/CEO </div>

                      <div class="d-none desc-1 p-small">
                        <div class="description-inner mt-2">
                          Ahmer is a highly accomplished professional with a
                          successful track record in customer experience and
                          business development. His expertise spans various
                          areas of the telecommunications industry, including
                          telephony, contact centre solutions, and communication
                          APIs.
                          <br />
                          As the Co-Founder of Kasookoo, Ahmer has been
                          instrumental in delivering communication technology
                          platforms to businesses in the Middle East and Africa.
                          With his leadership, the company has achieved a strong
                          local presence and global reach, supported by
                          strategic partnerships, venture financing, and
                          early-stage ventures.
                          <br />
                          Throughout his career, Ahmer has held key positions in
                          renowned organizations such as OneWeb, GSMA, Yuave (a
                          Nokia spin-off company), and Jaguar Land Rover. He has
                          played a vital role in driving strategic partnerships,
                          procurement, and business development initiatives.
                          <br />
                          Ahmer holds a bachelor's degree in Electronics
                          Engineering from Ghulam Ishaq Khan Institute of
                          Engineering Sciences and Technology in Pakistan. He
                          also holds a Business Administration and Management
                          certification from Bayes Business School, where he
                          focused on areas such as business finance, leadership,
                          and developing digital capabilities.
                          <br />
                          With his extensive expertise and diverse skill set,
                          Ahmer is well-equipped to lead and contribute to the
                          growth and success of organizations in the customer
                          experience and telecommunications industry.
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-5">
                  <div class="team-member">
                    <div class="tm-photo text-center mx-auto mb-3">
                      <img src={teamMember2Img} alt="Frame 46259/" />
                    </div>
                    <div class="tm-content">
                      <div class="name p-small pb-3 mb-3">
                        Waqas Akram
                        <span class="float-end">
                          <img
                            class="view-caret"
                            src={down_arrow}
                            alt="caret"
                            onClick={(event) => {
                              toggleContent(event, 2);
                            }}
                            style={{ transform: "rotate(90deg)" }}
                          />
                        </span>
                      </div>
                      <div class="desc p-small">
                        Co-founder/Head of Products & Technology
                      </div>

                      <div class="d-none desc-2 p-small">
                        <div class="description-inner mt-2">
                          Muhammad Waqas Akram is an experienced product manager
                          and business strategist with a strong background in
                          technology. He has successfully managed complex
                          projects in various areas such as new product
                          development, market entry, and channel development.
                          His expertise lies in product management, strategy,
                          vision, and roadmap, and he is skilled in agile
                          project management and market research.
                          <br />
                          Muhammad is currently the Co-Founder and Head of
                          Product and Technology at Kasookoo, a position he has
                          held since September 2021. He has previously worked in
                          roles such as program manager for eCommerce at Ingram
                          Micro and product manager for eCommerce at Grainger
                          Canada. Muhammad is also experienced in managing
                          multicultural teams and possesses skills in strategic
                          planning and go-to-market strategy. <br />
                          Muhammad has achieved notable accomplishments
                          throughout his career, including developing effective
                          go-to-market strategies for product launches, advising
                          businesses on implementing Scrum for product
                          development, and significantly improving radio network
                          availability in a mobile carrier project. He holds an
                          MBA in Marketing and Strategy from Alliance Manchester
                          Business School and a B.S. in Electronics Engineering
                          from Ghulam Ishaq Khan Institute of Engineering
                          Sciences and Technology.
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-5">
                  <div class="team-member">
                    <div class="tm-photo text-center mx-auto mb-3">
                      <img src={teamMember3Img} alt="Frame 46261" />
                    </div>
                    <div class="tm-content">
                      <div class="name p-small pb-3 mb-3">
                        Akin Braithwaite
                        <span class="float-end">
                          <img
                            class="view-caret"
                            src={down_arrow}
                            alt="caret"
                            onClick={(event) => {
                              toggleContent(event, 3);
                            }}
                            style={{ transform: "rotate(90deg)" }}
                          />
                        </span>
                      </div>
                      <div class="desc p-small">
                        Co-founder/Head of Sales & Customer Success
                      </div>

                      <div class="d-none desc-3 p-small">
                        <div class="description-inner mt-2">
                          Akin Braithwaite is a Co-founder and Head of Sales &
                          Customer Success at Kasookoo Communications Ltd, where
                          he focuses on growing corporate revenue through
                          high-performance customer service teams and strategic
                          implementation of Customer Relationship Management
                          systems. With expertise in Change Management and
                          Telecommunications, Akin is an innovative leader with
                          extensive experience in Contact Centre Management,
                          particularly in rapid growth markets. <br />
                          In his current role, Akin drives the digital
                          transformation of organizations across Africa by
                          offering cutting-edge Cloud Communications solutions.
                          He forms a part of the Leads at Kasookoo Contact
                          Centre as a Service (CCaaS) initiative, which aims to
                          digitize business communication and collaboration in
                          the region.
                          <br />
                          Prior to his current position, Akin worked as a
                          Management Consultant and Chief Customer Relations
                          Officer, MTN, Nigeria where he achieved recognition
                          for developing award-winning Customer Care divisions
                          and driving customer service excellence. He also held
                          the role of General Manager, overseeing the growth and
                          success of the Southwest Nigeria region. <br />
                          Akin's educational background includes studies in
                          Corporate Finance, Insurance, and Management. He holds
                          a Bachelor of Arts degree in English and Biblical
                          Studies. Outside of work, Akin is involved in
                          activities such as Judo and volunteering as a
                          Counselor to Correctional Institutions.
                        </div>
                        <div class="social-links mt-2"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="num-section-18"
          class="w-100 position-relative block-centered_title "
        >
          <style></style>

          <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
            <div class="container">
              <div class="row ">
                <div class="col-12 mx-auto col-md-8">
                  <h2 class="style-h2">Backed by</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="num-section-19"
          class="w-100 position-relative block-centered_title "
        >
          <style></style>

          <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
            <div class="container">
              <div class="row ">
                <div class="col-12 mx-auto col-md-8">
                  <p class="p-small">
                    Kasookoo is supported by visionary investors, combining
                    decades of invaluable industry expertise and a deep
                    understanding of CX dynamics that enables us to deliver
                    exceptional solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="num-section-20"
          class="w-100 position-relative block-logos pb-3 hide-slick-dots-on-desktop"
        >
          <style></style>

          <div class="w-100 section-logos-slider py-3 py-md-5 bg- ">
            {/* <Slider/> */}
           
              <div className="container">
              <div className="row" >
                <div className="col-sm-3  text-center"><img src={launchAfrica}   style={{height:"50%"}}/></div>
                <div className="col-sm-3 text-center"><img src={SSEAN} style={{height:"50%"}} /></div>
                <div className="col-sm-3 text-center"><img src={tomDavis}   style={{height:"50%"}}/></div>
                <div className="col-sm-3 text-center"><img src={mattBrown}  style={{height:"50%"}}/></div>
              
              </div>
              </div>
            </div>
         
        </div>
        <div
          id="num-section-21"
          class="w-100 position-relative block-cta_strip_2 "
          style={{ backgroundColor: "#652580" }}
        >
          <style></style>

          <div class="w-100 section-cta_strip_2 text-white text-center text-md-start ">
            <div class="container pt-5 pt-md-0">
              <div class="row py-md-5">
                <div
                  class="col-12 col-md-6 d-flex ps-md-5  order-last order-md-last"
                  style={{ verticalAlign: "bottom" }}
                >
                  <img
                    src={aboutjoin}
                    alt="Want to join Kasookoo?"
                    class="w-100 img-class"
                    style={{ objectFit: "contain" }}
                  />
                </div>
                <div class="col-12 col-md-6 py-4 py-md-5 my-auto px-lg-4 ">
                  <div class="d-block w-100">
                    <h2 class="style-h2">Ready to join Kasookoo?</h2>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-big">
                      <p>
                        If you want to join the journey to redefine customer
                        experiences and engagement, then we are continuously
                        seeking individuals who are customer-focused and eager
                        to make an impact. Join our team in driving
                        transformative change.
                      </p>
                    </div>
                  </div>
                  <div class="d-block w-100 mt-4">
                    <a
                      href="/#/careers"
                      class="blact_btn"
                      target=""
                      style={{ backgroundColor: "#27A7CC" }}
                    >
                      See our Careers Page
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
